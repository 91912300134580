import { api } from '@ion/api';
import { PosthogEvents, trackEvent } from '@ion/api/posthog';
import { type ModalProps, Button, Modal, toast, Tooltip } from '@ion/design-system';
import { useSession } from '@ion/next-auth/react';
import { Lock } from 'lucide-react';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import { Icons } from '@/components/icons';
import Spinner from '@/components/ui/spinner';

interface NewProjectModalProps extends ModalProps {}

const NewProjectModal: React.FC<NewProjectModalProps> = ({ trigger, open, onOpenChange }) => {
  const { data: userData } = useSession();

  const [selectedFramework, setSelectedFramework] = useState<string | null>('Vite + React');
  const [selectedDesignSystem, setSelectedDesignSystem] = useState<'ion' | 'none' | 'shadcn' | 'material-ui'>('ion');

  useEffect(() => {
    if (userData?.user && open) {
      trackEvent(PosthogEvents.CREATED_PROJECT_MODAL_OPENED, userData.user);
    }
  }, [open, userData?.user]);

  const initializeProject = api.generations.initializeProject.useMutation({
    onSuccess: (data) => {
      if (userData) {
        trackEvent(PosthogEvents.CREATED_PROJECT, userData.user, {
          project: data.id,
          selectedFramework,
          selectedDesignSystem,
        });
      }
      if (window.history) {
        history.pushState({}, window.location.href);
      }
      window.location.replace(`/project/${data.id}`);
    },
    onError: () => {
      toast.error('Failed to create project');
    },
  });

  const frameworks = [
    { name: 'Vite + React', logo: '/vite-react-logo.png', disabled: false, tooltip: 'Recommended' },
    { name: 'Nextjs', logo: '/next-logo.png', disabled: true, tooltip: 'Coming soon' },
    { name: 'Nuxt + Vue', logo: '/nuxt-vue-logo.png', disabled: true, tooltip: 'Coming soon' },
  ];

  const designSystems = [
    {
      name: 'ion design system',
      designSystem: 'ion' as const,
      logo: '/logo-only-yellow.png',
      disabled: false,
      tooltip: 'Recommended',
    },
    {
      name: 'none',
      designSystem: 'none' as const,
      icon: <Icons.blankPage className="h-6 mb-2 w-6 text-foreground" />,
      disabled: false,
      tooltip: 'Advanced - only select if you know what you are doing',
    },
    {
      name: 'shadcn',

      designSystem: 'shadcn' as const,
      logo: '/shadcn-logo.png',
      disabled: true,
      tooltip: 'Coming soon',
    },
    {
      name: 'material ui',

      designSystem: 'material-ui' as const,
      logo: '/material-ui-logo.png',
      disabled: true,
      tooltip: 'Coming soon',
    },
  ];

  return (
    <Modal
      trigger={trigger}
      open={open}
      onOpenChange={onOpenChange}
      title="New Project"
      footerBordered={false}
      headerBordered={false}
      showClose
      className="w-[600px]"
    >
      <div className="flex flex-col gap-4">
        <div>
          <h3 className="text-sm font-semibold text-secondary mb-2">Connect to my github</h3>
          <div className="bg-neutral-accent rounded-radius p-4">
            <div className="flex items-center mb-2">
              <Lock size={20} className="text-neutral mr-2" />
              <span className="text-sm font-semibold text-neutral">Pro Feature</span>
            </div>
            <p className="text-sm text-subtle mb-4">
              Upgrade to Pro to unlock GitHub integration and connect your own codebase. This will allow you to re-use
              your existing components and styles.
            </p>
            <Link
              href="https://cal.com/samraaj/30min"
              target="_blank"
              onClick={() => {
                if (userData?.user) {
                  trackEvent(PosthogEvents.UPGRADE_TO_PRO_CLICKED, userData.user);
                }
              }}
            >
              <Button variant="soft" color="primary" size="md" className="w-full">
                Upgrade to Pro
              </Button>
            </Link>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-secondary mb-2">Select a framework</h3>
          <div className="grid grid-cols-3 gap-4">
            {frameworks.map((framework) => (
              <Tooltip key={framework.name} content={framework.tooltip} side="top">
                <div
                  key={framework.name}
                  className={`relative p-4 border rounded-radius ${
                    selectedFramework === framework.name ? 'border-primary bg-container' : 'border-stroke'
                  } ${framework.disabled ? 'opacity-50' : 'cursor-pointer'}`}
                  onClick={() => !framework.disabled && setSelectedFramework(framework.name)}
                >
                  <img src={framework.logo} alt={framework.name} className="h-6 mb-2" />
                  <p className="text-sm font-medium text-foreground text-left">{framework.name}</p>
                  {framework.disabled && <Lock size={16} className="absolute top-2 right-2 text-neutral" />}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-sm font-semibold text-secondary mb-2">Select a design system</h3>
          <div className="grid grid-cols-2 gap-4">
            {designSystems.map((designSystem) => (
              <Tooltip key={designSystem.name} content={designSystem.tooltip} side="top">
                <div
                  key={designSystem.name}
                  className={`relative p-4 border rounded-radius text-foreground ${
                    selectedDesignSystem === designSystem.designSystem ? 'border-primary bg-container' : 'border-stroke'
                  } ${designSystem.disabled ? 'opacity-50' : 'cursor-pointer'}`}
                  onClick={() => !designSystem.disabled && setSelectedDesignSystem(designSystem.designSystem)}
                >
                  {designSystem.logo && (
                    <img src={designSystem.logo} alt={designSystem.name} className="h-6 mb-2 text-foreground" />
                  )}
                  {designSystem.icon}
                  <p className="text-sm font-medium text-foreground text-left">{designSystem.name}</p>
                  {designSystem.disabled && <Lock size={16} className="absolute top-2 right-2 text-neutral" />}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>

        <Button
          variant="filled"
          color="primary"
          size="md"
          className="w-full mt-6"
          disabled={
            !selectedFramework || !selectedDesignSystem || initializeProject.isLoading || initializeProject.isSuccess
          }
          iconLeading={
            initializeProject.isLoading ? (
              <Spinner size="sm" />
            ) : initializeProject.isSuccess ? (
              <Icons.check className="h-4 w-4" />
            ) : undefined
          }
          onClick={() =>
            initializeProject.mutate({
              designSystem: selectedDesignSystem as 'ion' | 'none',
            })
          }
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export { NewProjectModal };
