import type * as babel from '@babel/types';
import { type ComponentPrimitiveType } from '@ion/env';
import type * as Figma from '@knapsack-labs/figma-api';
import { z } from 'zod';

import { type ComponentPropertyType } from './figma';
import { type YDLElement } from './ydl';

export type Component = {
  name: string;
  type: string;
  id: string;
  properties: ComponentPropertyValue[];
  backgrounds: {
    type: string;
    visible: boolean;
    opacity: number;
    blendMode: string;
    color: {
      r: number;
      g: number;
      b: number;
      a: number;
    };
  }[];
  bottomLeftRadius: number;
  bottomRightRadius: number;
  topRightRadius: number;
  topLeftRadius: number;
  cornerRadius: number;
  fills: {
    type: string;
    visible: boolean;
    opacity: number;
    blendMode: string;
    color: {
      r: number;
      g: number;
      b: number;
      a: number;
    };
  }[];
  horizontalPadding: number;
  verticalPadding: number;
};

type ComponentPropertyValue = {
  key: string;
  value: string;
};

export type ComponentSet = {
  name: string;
  type: string;
  id: string;
  children: Component[];
};

export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'full';

export type AST = {
  name: string;
  type?: string;
  JSX?: string;
  JSXEnd?: string;
  children?: AST[];
  depth?: number;
  uuid?: string;
};

const FigmaComponentIdentifier = z
  .object({
    componentId: z.string().or(z.null()),
    componentSetId: z.string(),
  })
  .or(
    z.object({
      componentId: z.string(),
      componentSetId: z.string().or(z.null()),
    })
  );

export type FigmaComponentIdentifier = z.infer<typeof FigmaComponentIdentifier>;

export const ComponentRetrievedSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  code: z.string(),
  path: z.string(),
  importPath: z.string(),
  props: z.array(z.string()).optional(),
  generateInline: z.boolean().optional(),
  identifier: z.string().optional(),
  receivesProps: z.boolean().optional(),
  // (typeof ION_COMPONENTS)[number]
  ionComponent: z.string().optional(),
  fromUserRepository: z.boolean().default(false),
  matchingIds: z.array(z.string()),
  propertyMap: z.record(z.string()).optional(),
  hasChildren: z.boolean().optional(),
  figmaLink: z.string().optional(),
});

export type ComponentRetrieved = z.infer<typeof ComponentRetrievedSchema>;

export type hierarchalStubAST = {
  name: string;
  code: string;
  type: string;
  children?: hierarchalStubAST[];
  props?: string[];
  propsDescribed?: string;
  functionalCode?: string;
};

export type TranspiledNode = {
  type?: string;
  name?: string;
  value?: string;
  closingElement?: boolean;
  className?: string;
  attributes?: string[];
  children?: TranspiledNode[];
  indexInArr1?: number;
  indexInArr2?: number;
};

export interface CreateJSXElementProps {
  componentRetrieved: ComponentRetrieved | InlineGeneration;
  selfClosing?: boolean;
  jsxAttributes?: Array<babel.JSXAttribute>;
}

export interface BabelFunction {
  YDLFunctionDeclaration: babel.FunctionDeclaration;
  YDLIdentifier: babel.Identifier;
  typeSignature?: babel.TSTypeAliasDeclaration | null;
}

export type ComponentProperty = {
  key: string;
  rawKey: string;
  values?: (string | boolean)[];
  defaultValue?: string | boolean;
  // converter property does not get caught in component set generations
  type: ComponentPropertyType | 'CONVERTER_PROPERTY' | 'IMPORTED_TYPE';
  required?: boolean;
  importedFrom?: string;
  name?: string;
  fromExposedInstanceId?: string;
};

export type Color = {
  name: string;
  value: string;
};

export type Colors = Color[];

export type InlineGeneration = {
  id: string;
  identifier: string;
  parentGenerationId: string;
  name: string;
  node: Figma.Node<keyof Figma.NodeTypes>;
  ydl: YDLElement | undefined;
  additionalBlockStatements: babel.Statement[];
  propsUsed: ComponentProperty[];
};

export type InlineGenerations = {
  [key: string]: InlineGeneration;
};

export type Generation = {
  id: string;
  node: Figma.Node<keyof Figma.NodeTypes>;
  name: string;
  path: string;
  importPath: string;
  ydl: YDLElement | undefined;
  template?: string;
  importDeclarations: babel.ImportDeclaration[];
  propsUsed: ComponentProperty[];
  additionalBlockStatements: babel.Statement[];
  declarations: babel.Statement[];
  inlineGenerations: InlineGenerations;
  code: string | undefined;
  componentType: ComponentPrimitiveType;
  shouldBeImprovedWithAI?: boolean;
  shouldBeAnimated?: boolean;
  isClientComponent?: boolean;
  /** A forced instance generation will generate as if it was an instance even if it's not */
  isForcedInstanceGeneration?: boolean;
  /** A forced generation will generate as if it was a component set even if it is not */
  isForcedGeneration?: boolean;
  /** Is the generation likely a darkmode generation */
  isDarkModeGeneration?: boolean;
  /* 
  darkModeHits?: number;
  lightModeHits?: number;
  */
  hasChildren?: boolean;
};

export type Generations = {
  [key: string]: Generation;
};

export type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
};
