import { ComponentRetrievedSchema } from '@api/types';
import { type File } from '@api/types/updates';
import type * as Figma from '@knapsack-labs/figma-api';
import { link } from 'fs';
import { z } from 'zod';

export const FontLocationSchema = z.object({
  path: z.string(),
  content: z.string(),
  type: z.enum(['nextjs', 'vite']),
});
export type FontLocation = z.infer<typeof FontLocationSchema>;

export const outputSchema = z.object({
  files: z.array(
    z.object({
      path: z.string(),
      content: z.string(),
    })
  ),
});

export type GenerateCodeFromPromptOutput = z.infer<typeof outputSchema>;

export type GenerationResult = {
  files: File[];
  frameData: Figma.Node;
  frameName: string;
  frameId: string;
  fileKey: string;
  generatedCode: string;
  conflict?: boolean;
  currentCodePretty?: string;
} | null;

const GenerateCodeStreamFileSchema = z.object({
  path: z.string(),
  name: z.string(),
  content: z.string().optional(),
});
export { type File };
const FileSchema = z.object({
  path: z.string(),
  content: z.string().optional(),
  source: z.string().optional(),
});

export const GenerateCodeStreamMessageSchema = z.union([
  z.object({
    statusUpdate: z.enum(['initializing-metadata', 'improving-code-with-ai', 'generating-components']),
  }),
  z.object({
    statusUpdate: z.literal('constructing-generation-plan'),
    figmaNode: z.object({
      name: z.string(),
      link: z.string().url(),
    }),
  }),
  z.object({
    generationPlan: z.array(GenerateCodeStreamFileSchema),
  }),
  z.object({
    trigger: z.literal('generating-component'),
    component: GenerateCodeStreamFileSchema,
  }),
  z.object({
    trigger: z.literal('generated-component'),
    component: GenerateCodeStreamFileSchema,
    isMainGeneration: z.boolean(),
  }),
  z.object({
    statusUpdate: z.literal('finished'),
    files: z.array(FileSchema),
  }),
]);

export type GenerateCodeStreamMessage = z.infer<typeof GenerateCodeStreamMessageSchema>;

export const GenerateFromWebSchema = z.object({
  fileKey: z.string(),
  nodeId: z.string(),
  destinationPath: z.string(),
  tailwindConfig: z.string().optional(),
  globalsCss: z.string().optional(),
  components: z.array(ComponentRetrievedSchema).default([]),
  packageJson: z.string(),
  fontLocations: z.array(FontLocationSchema),
  useExperimental: z.boolean().optional(),
  generationId: z.string(),
  generatedFrom: z.enum(['default', 'onboarding']),
  previewUrl: z.string().url().optional(),
});

export type GenerateFromWebSchemaProps = z.infer<typeof GenerateFromWebSchema>;

export const GenerateFromAPISchema = z.object({
  fileKey: z.string(),
  nodeId: z.string(),
  destinationPath: z.string(),
  tailwindConfig: z.string().optional(),
  globalsCss: z.string().optional(),
  components: z.array(ComponentRetrievedSchema).default([]).optional(),
  packageJson: z.string(),
  useExperimental: z.boolean().optional(),
  previewUrl: z.string().url().optional(),
  figmaAccessToken: z.string(),
});

export type GenerateFromAPISchemaProps = z.infer<typeof GenerateFromAPISchema>;
