import { useVirtualizer } from '@tanstack/react-virtual';
import * as LucideIcons from 'lucide-react';
import { type LucideIcon } from 'lucide-react';
import { Check, Search } from 'lucide-react';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

// Filter out non-icon exports and type exports
const iconList = Object.entries(LucideIcons)
  .filter(([name, Icon]) => {
    // Keep only the Icon-suffixed entries
    return name.endsWith('Icon') && typeof Icon === 'object' && Icon !== null;
  })
  .map(([name, Icon]) => {
    const baseName = name.replace('Icon', '');
    return [baseName, Icon] as [string, LucideIcon];
  })
  .sort((a, b) => a[0].localeCompare(b[0]));

console.log('Available icons:', iconList.length);

export interface IconSelectProps {
  /** The currently selected icon name */
  value?: string;
  /** Callback when an icon is selected */
  onSelect?: (iconName: string) => void;
  /** Optional class name for the trigger button */
  className?: string;
  /** Optional placeholder text */
  placeholder?: string;
}

export const IconSelect = React.forwardRef<HTMLDivElement, IconSelectProps>(
  ({ value, onSelect, className, placeholder = 'Select an icon...' }, ref) => {
    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');

    // Filter icons based on search
    const filteredIcons = React.useMemo(() => {
      if (!searchQuery.trim()) return iconList;
      const searchLower = searchQuery.toLowerCase();
      return iconList.filter(([name]) => name.toLowerCase().includes(searchLower));
    }, [searchQuery]);

    React.useEffect(() => {
      if (open) {
        console.log('Filtered icons:', filteredIcons.length);
      }
    }, [open, filteredIcons]);

    // Virtual list setup
    const parentRef = React.useRef<HTMLDivElement>(null);
    const ICONS_PER_ROW = 4;
    const rowVirtualizer = useVirtualizer({
      count: Math.ceil(filteredIcons.length / ICONS_PER_ROW),
      getScrollElement: () => parentRef.current,
      estimateSize: () => 48, // Height of each row including gap
      overscan: 5,
    });

    const SelectedIcon = value ? (LucideIcons[value as keyof typeof LucideIcons] as LucideIcon) : null;

    return (
      <div ref={ref} className={className}>
        <div className="relative w-full">
          <button
            type="button"
            className={twMerge(
              'flex h-9 w-full items-center justify-between gap-2 rounded-radius-sm border border-stroke bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-subtle focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              'data-[state=open]:border-stroke-primary'
            )}
            onClick={() => setOpen(!open)}
          >
            <span className="flex items-center gap-2">
              {SelectedIcon ? (
                <>
                  <SelectedIcon className="h-4 w-4" />
                  <span>{value}</span>
                </>
              ) : (
                <span className="text-subtle">{placeholder}</span>
              )}
            </span>
          </button>

          {open && (
            <div className="absolute z-[9999] mt-2 w-full rounded-radius border border-stroke bg-background shadow-medium">
              <div className="flex h-9 w-full items-center gap-2 border-b border-stroke px-3">
                <Search className="h-4 w-4 text-subtle" />
                <input
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-1 bg-transparent text-sm outline-none placeholder:text-subtle disabled:cursor-not-allowed disabled:opacity-50"
                  placeholder="Search icons..."
                />
              </div>

              <div ref={parentRef} className="max-h-[300px] overflow-auto p-1">
                {filteredIcons.length === 0 ? (
                  <div className="p-2 text-sm text-subtle">No icons found</div>
                ) : (
                  <div
                    style={{
                      height: `${rowVirtualizer.getTotalSize()}px`,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                      const rowStart = virtualRow.index * ICONS_PER_ROW;
                      const rowIcons = filteredIcons.slice(rowStart, rowStart + ICONS_PER_ROW);

                      return (
                        <div
                          key={virtualRow.index}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                          }}
                          className="grid grid-cols-4 gap-1 px-1"
                        >
                          {rowIcons.map(([iconName, Icon]) => (
                            <button
                              key={iconName}
                              type="button"
                              onClick={() => {
                                onSelect?.(iconName);
                                setOpen(false);
                              }}
                              className="flex h-8 w-8 items-center justify-center rounded-radius-xs hover:bg-container relative"
                              title={iconName}
                            >
                              <Icon className="h-4 w-4" />
                              {value === iconName && (
                                <div className="absolute inset-0 rounded-radius-xs ring-1 ring-primary" />
                              )}
                            </button>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

IconSelect.displayName = 'IconSelect';
