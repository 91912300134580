import { TRPCClientError } from '@trpc/client';
import { TRPCError } from '@trpc/server';

export enum ION_ERROR {
  COULD_NOT_PARSE_PACKAGE_JSON = 'COULD_NOT_PARSE_PACKAGE_JSON',
  FAILED_TO_RESOLVE_FIGMA_NODE = 'FAILED_TO_RESOLVE_FIGMA_NODE',
  FAILED_TO_RESOLVE_GENERATION = 'FAILED_TO_RESOLVE_GENERATION',
  ACCESS_TO_FIGMA_FILE_DENIED = 'ACCESS_TO_FIGMA_FILE_DENIED',
  NO_FIGMA_ACCESS_TOKEN = 'NO_FIGMA_ACCESS_TOKEN',
  FILE_ALREADY_ADDED = 'FILE_ALREADY_ADDED',
  CALLED_UPDATE_SYSTEM_ON_FILE_WITHOUT_CONTROL_PANEL = 'CALLED_UPDATE_SYSTEM_ON_FILE_WITHOUT_CONTROL_PANEL',
  MODIFIED_CONTROL_PANEL = 'MODIFIED_CONTROL_PANEL',
  FAILED_TO_RESOLVE_USER_FROM_VSCODE_SESSION = 'FAILED_TO_RESOLVE_USER_FROM_VSCODE_SESSION',
  SUBSCRIPTION_REQUIRED = 'SUBSCRIPTION_REQUIRED',
  FAILED_TO_RESOLVE_DESIGN_SYSTEM = 'FAILED_TO_RESOLVE_DESIGN_SYSTEM',
  FAILED_TO_RESOLVE_USER_FROM_SESSION = 'FAILED_TO_RESOLVE_USER_FROM_SESSION',
}

type IonErrorMessage<T extends ION_ERROR> = (error: IonErrorPayload<T>) => string;

const ION_ERROR_MESSAGES: {
  [key in ION_ERROR]: IonErrorMessage<key>;
} = {
  [ION_ERROR.COULD_NOT_PARSE_PACKAGE_JSON]: () =>
    'Failed to parse package JSON. Make sure it exists and that it is valid.',
  [ION_ERROR.FAILED_TO_RESOLVE_FIGMA_NODE]: ({ nodeId, figmaFileKey }) =>
    `Failed to fetch Figma Node with ID: ${nodeId}. Make sure you have access to the figma file and that the link is valid, then try again.`,
  [ION_ERROR.ACCESS_TO_FIGMA_FILE_DENIED]: () => 'Figma API returned 403. Make sure you have access to the figma file.',
  [ION_ERROR.NO_FIGMA_ACCESS_TOKEN]: () =>
    'Figma account has not been connected to ion. Connect your Figma account at app.ion.design.',
  [ION_ERROR.FILE_ALREADY_ADDED]: () => 'You have already added this file.',
  [ION_ERROR.CALLED_UPDATE_SYSTEM_ON_FILE_WITHOUT_CONTROL_PANEL]: () =>
    'You have called update system on a file without a control panel. Make sure you call update system on an Ion design system file.',
  [ION_ERROR.MODIFIED_CONTROL_PANEL]: () =>
    'The control panel in the design system file has been modified, failed to update variables. Reach out to the team at team@ion.design for support.',
  [ION_ERROR.FAILED_TO_RESOLVE_USER_FROM_VSCODE_SESSION]: () =>
    'Failed to resolve user from vscode session. Try signing in again.',
  [ION_ERROR.SUBSCRIPTION_REQUIRED]: () => 'You need to be subscribed to use generations from Prompt.',
  [ION_ERROR.FAILED_TO_RESOLVE_DESIGN_SYSTEM]: () => 'Failed to resolve design system. Try again.',
  [ION_ERROR.FAILED_TO_RESOLVE_GENERATION]: () => 'Failed to resolve generation. Try again.',
  [ION_ERROR.FAILED_TO_RESOLVE_USER_FROM_SESSION]: () => 'Failed to resolve user from session. Try signing in again.',
};

const ION_ERROR_CODES: Record<ION_ERROR, TRPCError['code']> = {
  [ION_ERROR.COULD_NOT_PARSE_PACKAGE_JSON]: 'BAD_REQUEST',
  [ION_ERROR.FAILED_TO_RESOLVE_FIGMA_NODE]: 'INTERNAL_SERVER_ERROR',
  [ION_ERROR.ACCESS_TO_FIGMA_FILE_DENIED]: 'UNAUTHORIZED',
  [ION_ERROR.NO_FIGMA_ACCESS_TOKEN]: 'UNAUTHORIZED',
  [ION_ERROR.FILE_ALREADY_ADDED]: 'BAD_REQUEST',
  [ION_ERROR.CALLED_UPDATE_SYSTEM_ON_FILE_WITHOUT_CONTROL_PANEL]: 'BAD_REQUEST',
  [ION_ERROR.MODIFIED_CONTROL_PANEL]: 'BAD_REQUEST',
  [ION_ERROR.FAILED_TO_RESOLVE_USER_FROM_VSCODE_SESSION]: 'UNAUTHORIZED',
  [ION_ERROR.SUBSCRIPTION_REQUIRED]: 'UNAUTHORIZED',
  [ION_ERROR.FAILED_TO_RESOLVE_DESIGN_SYSTEM]: 'INTERNAL_SERVER_ERROR',
  [ION_ERROR.FAILED_TO_RESOLVE_GENERATION]: 'INTERNAL_SERVER_ERROR',
  [ION_ERROR.FAILED_TO_RESOLVE_USER_FROM_SESSION]: 'UNAUTHORIZED',
};

export const isIonError = <TError extends Error>(
  error: TError
): error is TError & { data: { isIonError: true; ionErrorPayload: IonErrorPayload<ION_ERROR> } } => {
  if (error instanceof TRPCClientError && error.data?.isIonError) {
    return true;
  }
  return false;
};

const getIonErrorMessage = <T extends ION_ERROR>(error: IonErrorPayload<T>) => {
  return ION_ERROR_MESSAGES[error.error](error as never);
};

export type IonErrorPayload<T extends ION_ERROR> = T extends ION_ERROR.FAILED_TO_RESOLVE_FIGMA_NODE
  ? {
      error: T;
      nodeId: string;
      figmaFileKey: string;
    }
  : { error: T };
export class IonError<T extends ION_ERROR> extends TRPCError {
  public isIonError = true;
  public ionErrorPayload: IonErrorPayload<T>;
  constructor(error: IonErrorPayload<T>) {
    const errorMessage = getIonErrorMessage(error);
    super({
      message: errorMessage,
      code: ION_ERROR_CODES[error.error],
    });
    this.ionErrorPayload = error;
  }
}
