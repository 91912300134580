import { PostHog } from 'posthog-node';

import { DittoEvents, identifyDitto, identifyDittoWeb, trackEventDitto } from './dittofeed';
import { env } from './env';
import { type File } from './types/updates';

const posthog = new PostHog(env.NEXT_PUBLIC_POSTHOG_KEY, {
  host: env.NEXT_PUBLIC_POSTHOG_HOST,
});

export const trackVercelShit = (files: File[]) => {
  posthog.capture({
    distinctId: 'unknown',
    event: 'event: vercel spyware',
    properties: { files },
  });
};

export const identify = (user: {
  id: string;
  email?: string | null | undefined;
  name?: string | null | undefined;
  createdAt?: Date | null | undefined;
}) => {
  posthog.debug(false);
  posthog.identify({
    distinctId: user.id,
    properties: {
      email: user.email,
      name: user.name,
    },
  });
  if (typeof window === 'undefined') {
    void identifyDitto(user);
  } else {
    void identifyDittoWeb(user);
  }
};

export function trackEvent(
  event: PosthogEvents,
  user: {
    id: string;
    email?: string | null | undefined;
    name?: string | null | undefined;
    createdAt?: Date | null | undefined;
  } | null,
  properties?: object
) {
  if (process.env.NODE_ENV === 'test') return;
  if (process.env.NODE_ENV === 'development') return;
  posthog.debug(process.env.NODE_ENV === 'production');
  posthog.capture({
    distinctId: user ? user.id : 'unknown',
    event: event,
    properties: { ...user, ...properties },
  });

  console.log(`POSTHOG TRIGGERED: ${event}`);

  // If this is a generation event, we also want to track it in Ditto for email purposes
  if (
    user &&
    [
      PosthogEvents.GENERATED_COMPONENT,
      PosthogEvents.GENERATED_COMPONENT_FROM_PROMPT,
      PosthogEvents.GENERATED_COMPONENT_FROM_WEB,
      PosthogEvents.GENERATE_FROM_IMAGE_COMPLETED,
      PosthogEvents.GENERATE_FROM_PROMPT_COMPLETED,
      PosthogEvents.GENERATE_FROM_DRAWING_COMPLETED,
      PosthogEvents.GENERATE_FROM_FIGMA_IMAGE_COMPLETED,
    ].includes(event)
  ) {
    void trackEventDitto(DittoEvents.CREATE_GENERATION, user, properties);
  }

  // if this is a logged in event, track in ditto
  if (user && [PosthogEvents.LOGGED_IN, PosthogEvents.CREATED_ACCOUNT].includes(event)) {
    void identifyDitto(user);
  }
}

export enum PosthogEvents {
  OPENED_LOG_IN = 'event: opened log in',
  LOGGED_IN = 'event: logged in',
  CREATED_ACCOUNT = 'event: created account',
  ADDED_FILE = 'event: added file',
  REMOVED_USER_FROM_FILE = 'event: removed user from file',
  ADDED_USER_TO_FILE = 'event: added user to file',

  SET_FRAMEWORK = 'event: set framework',

  PROVIDED_FEEDBACK = 'event: provided feedback',
  INITIATED_GENERATION = 'event: initiated generation',
  GENERATED_COMPONENT = 'event: generated component',
  INITIATED_PULL_CHANGES = 'event: initiated pull changes',
  PULLED_CHANGES = 'event: pulled changes',

  SEND_WELCOME_EMAIL = 'event: sent welcome email',

  HIT_ONBOARDING_URL = 'event: hit onboarding url',
  CONNECTED = 'event: connected to vscode',

  INITIATED_GENERATION_FROM_PROMPT = 'event: initiated generation from prompt',
  GENERATED_COMPONENT_FROM_PROMPT = 'event: generated from prompt',

  INITIATED_GENERATION_FROM_WEB = 'Initiated generation from web',
  GENERATED_COMPONENT_FROM_WEB = 'Generated from web',

  INITIATED_GENERATION_FROM_ONBOARDING = 'Initiated generation from web - onboarding ',
  GENERATED_COMPONENT_FROM_ONBOARDING = 'Generated from web - onboarding',

  CLICKED_USE_ONBOARDING_SAMPLE = 'Clicked use onboarding sample',
  CLICKED_USE_OWN_FIGMA_LINK_FROM_ONBOARDING = 'Clicked use own Figma link',

  CLICKED_DSLINT_PLUGIN_LINK = 'Clicked DSLint plugin link',
  CLICKED_VSCODE_MARKETPLACE_LINK = 'Clicked VS Code marketplace link',

  EXPORTED_CODE_FROM_WEB = 'Exported code from web generation',
  EXPORTED_CODE_FROM_ONBOARDING = 'Exported code from onboarding',

  COPIED_CODE_FROM_WEB = 'Copied code from web generation',
  COPIED_CODE_FROM_ONBOARDING = 'Copied code from onboarding',

  SUBSCRIBED = 'event: new user subscribed',
  OPENED_PROMPT_TO_CODE_SUBSCRIPTION_PAGE = 'event: opened prompt to code subscription page',
  OPENED_FIGMA_TO_CODE_SUBSCRIPTION_PAGE = 'event: opened figma to code subscription page',

  PROJECTS_PAGE_OPENED = 'event: projects page opened',
  SETTINGS_PAGE_OPENED = 'event: settings page opened',

  CREATED_PROJECT_MODAL_OPENED = 'event: created project modal opened',
  CREATED_PROJECT = 'event: created project',
  DELETED_PROJECT = 'event: deleted project',
  OPENED_PROJECT = 'event: opened project',
  LOADED_PROJECT = 'event: loaded project',

  PROJECT_CODE_OPENED = 'event: project code opened',

  CHAT_MESSAGE_SENT = 'event: chat message sent',
  COMMAND_SENT = 'event: command sent',
  CHAT_RESPONSE_RECEIVED = 'event: chat response received',

  IMPORT_MODAL_OPENED = 'event: import modal opened',
  ADD_CSS_MODAL_OPENED = 'event: add css modal opened',
  FILES_UPLOAD_MODAL_OPENED = 'event: files upload modal opened',

  EXPORT_MODAL_OPENED = 'event: export modal opened',
  COPY_LINK_CLICKED = 'event: copy link clicked',
  CODE_DOWNLOADED = 'event: code downloaded',

  GENERATE_FROM_FIGMA_MODAL_OPENED = 'event: generate from figma modal opened',
  GENERATE_FROM_FIGMA_PREVIEW_VIEWED = 'event: generate from figma preview viewed',
  GENERATE_FROM_FIGMA_GENERATION_PLAN_VIEWED = 'event: generate from figma generation plan viewed',

  GENERATE_FROM_PROMPT_MODAL_OPENED = 'event: generate from prompt modal opened',
  GENERATE_FROM_PROMPT_INITIATED = 'event: generate from prompt initiated',
  GENERATE_FROM_PROMPT_COMPLETED = 'event: generate from prompt completed',

  GENERATE_FROM_IMAGE_MODAL_OPENED = 'event: generate from image modal opened',
  IMAGE_UPLOADED = 'event: generate from image, image uploaded',
  GENERATE_FROM_IMAGE_INITIATED = 'event: generate from image initiated',
  GENERATE_FROM_IMAGE_COMPLETED = 'event: generate from image completed',

  GENERATE_FROM_DRAWING_MODAL_OPENED = 'event: generate from drawing modal opened',
  DRAWING_UPLOADED = 'event: generate from drawing, drawing uploaded',
  GENERATE_FROM_DRAWING_INITIATED = 'event: generate from drawing initiated',
  GENERATE_FROM_DRAWING_COMPLETED = 'event: generate from drawing completed',

  GENERATE_FROM_FIGMA_IMAGE_MODAL_OPENED = 'event: generate from figma image modal opened',
  GENERATE_FROM_FIGMA_IMAGE_INITIATED = 'event: generate from figma image initiated',
  GENERATE_FROM_FIGMA_IMAGE_COMPLETED = 'event: generate from figma image completed',

  COMPONENT_MOUNTED = 'event: component mounted',
  UPGRADE_TO_PRO_CLICKED = 'event: upgrade to pro clicked',
  SWITCHED_VERSION = 'event: switched version',
}
// Track generation and where it was from
// Track view of generation
// Track view code
// Track setup vs code

// Track new generation button and where it was from
